/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import WebsiteData from "../../data/WebsiteData";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/slick-custom.css';

const Portfolio = () => {
  const settings = {
    dots: true,
    slidesToShow: 1,
    infinite: true,
    speed: 4000,
    autoplay: true,
    slidesToScroll: 1,
    pauseOnHover: true
  };

  return (
    <section sx={styles.section} id="portfolio">
      <Container>
        <SectionHeading
          sx={styles.heading}
          title={WebsiteData['Portfolio']['section_title']}
        />
        <Box>
          <Slider {...settings}>
            <img src='/portfolio-4.jpg' alt='portfolio 4' />
            <img src='/portfolio-5.jpg' alt='portfolio 5' />
            <img src='/portfolio-6.jpg' alt='portfolio 6' />
            <img src='/portfolio-7.jpg' alt='portfolio 7' />
            <img src='/portfolio-8.jpg' alt='portfolio 8' />
            <img src='/portfolio-9.jpg' alt='portfolio 9' />
            <img src='/portfolio-10.jpg' alt='portfolio 10' />
          </Slider>
        </Box>
        <SectionHeading
            sx={styles.headingAfter}
            description={WebsiteData['Portfolio']['text']}
        />
      </Container>
    </section>
  );
};

export default Portfolio;

const styles = {
  section: {
    pt: ['70px'],
    pb: [0],
    mt: ['10px'],
  },
  heading: {
    textTransform: 'uppercase',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  headingAfter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: ['40px'],
    p: {
      fontSize: '14px'
    }
  }
};
