import React from 'react';
import { Container, Box } from 'theme-ui';
import SectionHeading from '../components/section-heading';

const Description = () => {
    const createDiv = () => {
        document.getElementById('readMoreContent').innerHTML = "<p>At Biolab Studios, we manage several online outlets, our most prominent one being the massive “Trust me, I’m a biologist” community. This user base spans from biotech professionals to academics, teachers, students, and postdocs, providing us a unique platform in the global market, further enhanced by our presence on various social media platforms such as Facebook, Instagram, and Twitter.</p><p>We specialize in creating entertaining yet informative content for biotech enthusiasts while simultaneously promoting companies within the industry. This includes boosting company visibility on our Facebook page, designing engaging media assets, and consulting on campaign creation. During this process, our creative team, made up of experienced biotech insiders, prepares multiple options for the client before finalizing graphics and sharing posts. We can also share clients' existing content without our creative input, organically boosting the brand's online presence.</p><p>Contact us to explore how Biolab Studios can assist in achieving your brand goals!</p>";
    };

    return (
        <section id="whyus">
            <Container sx={styles.container}>
                <Box sx={styles.intro}>
                    <SectionHeading
                        sx={styles.heading}
                        title='Why choose Biolab Studios?'
                    />
                    <p><strong>&#x2705;	Unique Approach</strong>: We offer a special method to organic online growth that differentiates us from the competition.</p>
                    <p><strong>&#x2705;	Engaging Content</strong>: We masterfully craft content that is both entertaining and suitable for a professional audience.</p>
                    <p><strong>&#x2705;	Impressive Reach</strong>: Our "Trust me, I&rsquo;m a Biologist" community alone has around 1.7 million followers, and our content reaches 2-6 million individuals weekly.</p>
                    <p><strong>&#x2705;	Value for Money</strong>: Compared to conventional Facebook promotions, we ensure more engagement for every dollar spent, targeting a purely organic audience.</p>
                    <p><strong>&#x2705;	Diverse Audience</strong>: Our community encompasses biologists, biotech experts, entrepreneurs, young and senior researchers, and students.</p>
                    {/*<div id="readMoreContent">*/}
                    {/*    <button onClick={createDiv}>Read more</button>*/}
                    {/*</div>*/}
                </Box>
            </Container>
        </section>
    )
}

export default Description;

const styles = {
    container: {
        textAlign: "justify",
        marginTop: '10px',
        paddingTop: '70px',
    },
    heading: {
        textTransform: 'uppercase',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
    },
    intro: {
        // mt: ['70px'],
        button: {
            background: 'transparent',
            fontFamily: 'body',
            border: 0,
            cursor: 'pointer',
            color: '#5567FF',
            fontSize: '18px'
        },
        ul: {
            listStyleType: 'square',
            lineHeight: 2
        },
        p: {
            fontSize: '14px'
        }
    },
};