import React from 'react';
import { Container, Box } from 'theme-ui';
import SectionHeading from '../components/section-heading';
import Person from '../components/cards/person';

import WebsiteData from '../../data/WebsiteData';

const data = WebsiteData["About"]["data"];

const Description = () => {
    return (
        <section id="about">
            <section>
                <Container sx={styles.container}>
                    <Box>
                        <SectionHeading
                            sx={styles.heading}
                            title={WebsiteData['About']['title']}
                        />
                        <p>As part of our social media activities, we specialize in creating entertaining content for biotech fans while at the same time promoting companies within the field. This includes promoting companies on our Facebook page, developing engaging media posts, and advising on campaign creation. During this process, our creative team, composed of experienced biotech professionals, drafts multiple options for the client before the graphics are finalized and the posts can be shared. Additionally, we can also share clients&rsquo; existing content without our own creative input, organically promoting the brand&rsquo;s online presence.</p>
                        <p><strong>OUR SERVICES</strong></p>
                        <p>Our expertise lies in creating content that not only entertains biotech enthusiasts but also offers promotional opportunities for industry-related businesses. The services we provide include:</p>
                        <p><strong>Promotion</strong>: Boosting your company's visibility on Facebook, Instagram, Twitter and LinkedIn.</p>
                        <p><strong>Content Creation</strong>: Designing captivating media elements that resonate with your audience.</p>
                        <p><strong>Campaign Consulting</strong>: Benefit from our skilled team who will guide you in campaign strategies, providing multiple design options before finalizing content.</p>
                        <p><strong>Organic Promotion</strong>: If you have pre-existing content, we can promote it to enhance your brand's organic online footprint.</p>
                        <p>Keen to explore how Biolab Studios can elevate your brand? Get in touch today!</p>
                    </Box>
                </Container>
            </section>
            <section>
                <Container>
                    <Box sx={styles.features}>
                        {data?.map((item) => (
                            <Person key={item.id} className="feature-item" data={item} />
                        ))}
                    </Box>
                </Container>
            </section>
        </section>
    )
}

export default Description;

const styles = {
    container: {
        textAlign: "justify",
        marginTop: '10px',
        paddingTop: '70px',
        p: {
            fontSize: '14px'
        }
    },
    heading: {
        textTransform: 'uppercase',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
    },
    features: {
        gap: [6, null, null, '90px 40px'],
        display: ['grid'],
        maxWidth: 1175,
        marginTop: 30,
        mx: 'auto',
        justifyContent: ['center', null, null, 'unset'],
        gridTemplateColumns: [
            'repeat(1, 300px)',
            null,
            null,
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
        ],
        h5: {
            fontSize: '14px'
        }
    },
};