/** @jsx jsx */
import { jsx, Box, Image, Heading } from 'theme-ui';

const Person = ({ data, ...props }) => {
  return (
    <Box sx={styles.feature} {...props}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Box as="figure">
          <Image 
          src={data?.icon} 
          alt={data?.title} 
          sx={{
            border: "2px solid #379683",
            objectFit: "cover",
            borderRadius: "50%",
            width: "100px",
            height: "100px",
            display: "block",
          }}
          />
        </Box>
        <Heading as="h4">
          {data?.title}
        </Heading>
        <Heading as="h5">
          {data?.role}
        </Heading>
        <Heading as="h5">
          {data?.position}
        </Heading>
        {/* <Link href={data.moreLink} sx={styles.learnMore}>
          <span>Learn More</span> <Play width={14} height={14} /> */}
        {/* </Link> */}
      </Box>
    </Box>
  );
};

export default Person;

const styles = {
  feature: {
    // display: [null, null, null, null, null, 'flex'],
    textAlign: ['justify', 'justify', null, null, null, 'justify'],
    figure: {
      minWidth: [42, null, null, 70],
      // mr: [null, null, null, null, null, 6],
      // mb: [3, null, null, null, null, 0],
      mb: 3
    },
    h4: {
      fontSize: [2, null, null, 3],
      fontWeight: 'bold',
      lineHeight: 1.68,
      color: 'heading',
      mb: 3,
    },
    h5: {
      fontSize: [1, null, null, 2],
      fontWeight: 'italic',
      lineHeight: 1.68,
      color: 'heading',
      mb: 3,
    },
    p: {
      fontSize: [1, null, null, 2],
      lineHeight: 1.88,
      color: 'text',
    },
  },
  learnMore: {
    color: 'primary',
    cursor: 'pointer',
    fontSize: [1, null, null, '13px'],
    fontWeight: 700,
    display: 'inline-flex',
    alignItems: 'baseline',
    textDecoration: 'none',
    letterSpacing: 1,
    lineHeight: 1,
    textTransform: 'uppercase',
    mt: 4,
    svg: {
      ml: 2,
    },
  },
};
