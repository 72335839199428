import React from 'react';
import {Container, Box, Textarea, Input, Button, Label, Image} from 'theme-ui';
import SectionHeading from '../components/section-heading';
import ContactImage from '../assets/images/contact-us.png'

import WebsiteData from '../../data/WebsiteData';


const Contact = () => {
    return (
        <section id="contact">
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: '800px',
                    mt: ['10px'],
                    mb: ['10px'],
                    pt: ['60px'],
                    pb: ['60px']
                }}
            >
                <Box
                    sx={{
                        minWidth: "400px"
                    }}>
                    <SectionHeading
                        sx={styles.heading}
                        title={WebsiteData['Contact']['title']}
                    />

                    <Box sx={styles.features}>
                        <Image src={ContactImage} alt="contact us" sx={styles.contactImage} />
                        <form
                            name="Contact Form"
                            method="POST"
                            netlify-honeypot="bot-field"
                            data-netlify="true">
                            <input type="hidden" name="form-name" value="Contact Form" />
                            <Label>Your Name:</Label>
                            <Input sx={styles.input} type="text" name="name" required />
                            <Label>Your Email:</Label>
                            <Input sx={styles.input} type="email" name="email" required />
                            <Label>Message:</Label>
                            <Textarea sx={styles.textarea} rows="5" name="message" required />
                            <Button type="submit">Contact us</Button>
                        </form>
                    </Box>
                </Box>
            </Container>
        </section>
    )
}

export default Contact;

const styles = {
    features: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        gap: [6, null, null, '90px 40px'],
        maxWidth: 1175,
        marginTop: 30,
        mx: 'auto',
        justifyContent: ['center', null, null, 'unset'],
        gridTemplateColumns: [
            'repeat(1, 300px)',
            null,
            null,
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
        ],
        '@media only screen and (max-width: 720px)': {
            display: 'block'
        },
    },
    contactImage: {
        maxWidth: '400px',
        borderRadius: '10px',
        '@media only screen and (max-width: 720px)': {
            marginBottom: '30px'
        },
    },
    input: {
        minHeight: 40,
        marginBottom: 5,
        outline: 0,
        '&:focus': {
            borderColor: 0,
            boxShadow: 'unset',
            outline: 'none',
        },
    },
    textarea: {
        borderColor: 'borderColor',
        marginBottom: 5,
        outline: 0,
        '&:focus': {
            borderColor: '#5567FF',
            boxShadow: 'unset',
            outline: 'none',
        },
    },
    heading: {
        textTransform: 'uppercase',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
    }
};
