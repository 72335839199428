/** @jsx jsx */
import {jsx, Container, Heading, Image} from 'theme-ui';
import landingImage from 'assets/images/landing.jpg';

const Banner = () => {
  return (
    // <Parallax
    //   bgImage={BackgroundImage}
    //   bgImageSizes='cover'
    //   strength={500}
    //   // renderLayer={percentage => (
    //   //     <div
    //   //         style={{
    //   //           position: 'absolute',
    //   //           background: `rgba(0, 0, 0, 0.6)`,
    //   //           left: '0',
    //   //           top: '0',
    //   //           width: '100%',
    //   //           height: '100%',
    //   //         }}
    //   //     />
    //   // )}
    // >

      <section id="home" sx={styles.section}>
        <Container sx={styles.topContainer}>
          <div sx={styles.content}>
            <div>
              <Heading as="h1" sx={styles.motto}>
                Advertise where Biotech professionals actually hang out
              </Heading>
              <p>
                Run by active biotech researchers, Biolab Studios provides the optimum promotional platform for biotech
                companies seeking to build up a positive online presence, improve brand awareness, and reach out to top
                talent within this specific field.
              </p>
            </div>
            <div sx={styles.imageContainer}>
              <Image src={landingImage} alt="biolabs" />
            </div>
          </div>
        </Container>
      </section>
    // </Parallax>
  );
};

export default Banner;

const styles = {
  section: {
    backgroundSize: "cover",
    pt: [14, null, null, null, 10, 14],
    pb: [14, 0, null, null, 10, 14],
    position: 'relative',
    zIndex: 0,
    '::before': {
      backgroundColor: 'white',
      content: `''`,
      position: 'absolute',
      height: [30, null, null, 70, 85, 120],
      bottom: 0,
      width: '100%',
      zIndex: -1,
    },
  },
  topContainer: {
    minHeight: [null, null, null, null, '100vh', '100vh'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    maxWidth: [null, null, null, null, null],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'center',
    gap: 10,
    '@media (max-width: 1023px)': {
      display: 'block',
      paddingTop: '70px',
      marginTop: '10px'
    },
    h1: {
      color: '#0F2137',
      fontSize: ['28px', null, null, 12, 15],
      lineHeight: 1.35,
    },
    p: {
      // color: rgba('white', 0.8),
      fontSize: '14px',
      marginTop: [3, null, null, 6],
    },
  },
  imageContainer: {
    width: '200%',
    img: {
      borderRadius: '10px'
    },
    '@media (max-width: 1023px)': {
      width: '100%',
      img: {
        maxWidth: '100%'
      }
    },
  },
  motto: {
    textTransform: 'uppercase',
    fontWeight: [900, null, null, 900]
  }
};
